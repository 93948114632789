<script setup>
  import md from 'markdown-it';

  const props = defineProps(['content']);

  const renderer = md({
    html: true,
    linkify: true,
    typographer: true,
  });
</script>
<template>
  <div v-html="renderer.render(content)" />
</template>
